<template>
  <b-container fluid >
    <b-row>
      <b-col md="12" class="heading_alignment">
        <iq-card style="padding-left:20px; padding-right:20px">
          <template v-slot:headerTitle>
            <h4 class="card-title">
              {{cvCardTitle}}
            </h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="eventLevelAdd">
              {{cvbtnAddNew}}
            </b-button>
          </template>
          <template>
            <b-row>
              <b-col sm="6" md="6" lg="6" xl="6" class="mb-2">
                <b-form-input
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>
              <!-- <b-col md="8">
                <b-button size="sm" variant="outline-secondary" class="pull-right mr-2">Excel</b-button>
                <b-button size="sm" variant="outline-secondary" class="pull-right mr-2">Csv</b-button>
                <b-button size="sm" variant="outline-secondary" class="pull-right mr-2">Coustomize</b-button>
              </b-col> -->
            </b-row>
            <b-row>
              <b-col md="12" v-if="eventLevelObjList && eventLevelObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="eventLevelObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentPage"
                  :per-page="perPage">
                  <template v-slot:cell(created_on)="data">
                    {{getFormattedDateTime(data.item.created_on)}}
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="eventLevelEdit('MODAL', data.item)" v-if="!data.item.editable">
                      <i class="ri-ball-pen-fill m-0"></i>
                    </b-button>
                    <b-button variant=" iq-bg-danger" @click="showeventLevelDeleteDialog(data.item)" size="sm">
                      <i class="ri-delete-bin-line m-0"></i>
                    </b-button>
                  </template>
                </b-table>
              </b-col>
              <b-col md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1">
              </b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModeleventLevelAdd"
      scrollable
      :title="cvAddModalHeader"
      size="xl">
      <eventLevelAdd :propOpenedInModal="true" @emitCloseeventLevelAddModal="closeeventLevelAddModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="eventLevelAdd('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeeventLevelAddModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModeleventLevelEdit"
      scrollable
      :title="cvEditModalHeader"
      size="xl">
      <eventLevelEdit :propOpenedInModal="true" :propeventLevelObj="eventLevelEditObj" @emitCloseeventLevelEditModal="closeeventLevelEditModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="eventLevelEdit('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeeventLevelEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModeleventLevelDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>
        {{cvDelMessage}}
      </p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModeleventLevelDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="eventLevelDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>
<script>
import { eventLevels } from "../../../FackApi/api/eventLevel.js"
import eventLevelAdd from "./EventLevelAdd.vue"
import eventLevelEdit from "./EventLevelEdit.vue"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse.js"

export default {
  name: "eventLevelList",
  components: {
    eventLevelAdd,
    eventLevelEdit
  },
  data () {
    return {
      cvCardTitle: "Event Levels",
      cvbtnAddNew: "Add New",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit Event Level",
      cvAddModalHeader: "Add Event Level",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Event Level List Response",
      showModeleventLevelAdd: false,
      showModeleventLevelEdit: false,
      showModeleventLevelDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left", sortable: true },

        { label: "Event Level Name", key: "event_lev_name", class: "text-left", sortable: true },
        { label: "Created On ", key: "created_on", class: "text-left", sortable: true },
        { label: "Action ", key: "action", class: "text-left", sortable: false }
      ],
      eventLevelObjList: null,
      eventLevelEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      delObj: null
    }
  },
  mounted () {
    this.eventLevelList()
  },
  methods: {
    /**
     * getFormattedDateTime
     */
    getFormattedDateTime (datetime) {
      try {
        return moment(datetime).format("DD-MM-YYYY hh:mm:ss")
      }
      catch (err) {
        console.error("Exception occurred at getFormattedDateTime() and Exception:", err.message)
      }
    },
    /**
     * eventLevelList
     */
    async eventLevelList () {
      try {
        let eventLevelListResp = await eventLevels.eventLevelList(this)
        if (eventLevelListResp.resp_status) {
          this.eventLevelObjList = eventLevelListResp.resp_data.data
          this.totalRows = eventLevelListResp.resp_data.count
        }
        else {
          this.toastMsg = eventLevelListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at eventLevelList() and Exception:", err.message)
      }
    },
    /**
     * eventLevelAdd
     */
    eventLevelAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/admin/eventLevel_add")
        }
        else {
          this.showModeleventLevelAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at eventLevelAdd() and Exception:", err.message)
      }
    },
    /**
     * eventLevelEdit
     */
    eventLevelEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/admin/eventLevel_edit/" + this.eventLevelEditObj.event_lev_id)
        }
        else {
          this.eventLevelEditObj = item
          this.showModeleventLevelEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at eventLevelEdit() and Exception:", err.message)
      }
    },
    /**
     * showeventLevelDeleteDialog
     */
    showeventLevelDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModeleventLevelDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showeventLevelDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * eventLevelDelete
     */
    async eventLevelDelete () {
      try {
        let eventLevelDelResp = await eventLevels.eventLevelDelete(this, this.delObj.event_lev_id)
        await ApiResponse.responseMessageDisplay(this, eventLevelDelResp)
        if (eventLevelDelResp && !eventLevelDelResp) {
          this.showModeleventLevelDelete = false
          return false
        }
        let index = this.eventLevelObjList.indexOf(this.delObj)
        this.eventLevelObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModeleventLevelDelete = false
      }
      catch (err) {
        console.error("Exception occurred at eventLevelDelete() and Exception:", err.message)
      }
    },
    /**
     * closeeventLevelAddModal
     */
    closeeventLevelAddModal (eventLevelAddData) {
      try {
        if (eventLevelAddData) {
          if (this.eventLevelObjList && this.eventLevelObjList.length >= 1) {
            let profBulActLevObjLength = this.eventLevelObjList.length
            let lastId = this.eventLevelObjList[profBulActLevObjLength - 1]["id"]
            eventLevelAddData.id = lastId + 1
          }
          else {
            this.eventLevelObjList = []
            eventLevelAddData.id = 11111
          }
          // eventLevelAddData.event_lev_id = `EVELEV${eventLevelAddData.id}`
          eventLevelAddData.created_on = moment()
          this.eventLevelObjList.unshift(eventLevelAddData)
          this.totalRows = this.totalRows + 1
        }
        this.showModeleventLevelAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closeeventLevelAddModal() and Exception:", err.message)
      }
    },
    /**
     * closeeventLevelEditModal
     */
    closeeventLevelEditModal () {
      try {
        this.showModeleventLevelEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeeventLevelEditModal() and Exception:", err.message)
      }
    }
  }
}
</script>
